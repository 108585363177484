import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import VueGtag from "vue-gtag";

/* import the oh-vue-icons core */
import { OhVueIcon, addIcons } from "oh-vue-icons";
import { FaEyeDropper, CoMagnifyingGlass, FaListUl, LaCloudDownloadAltSolid, CoLibraryAdd } from "oh-vue-icons/icons";

addIcons(FaEyeDropper, CoMagnifyingGlass, FaListUl, LaCloudDownloadAltSolid, CoLibraryAdd);

const app = createApp(App)
app.use(router)
app.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_MEASUREMENT_ID, params: { send_page_view: true } },
}, router)
app.component("v-icon", OhVueIcon);
app.mount('#app')
