<template>
    <header class="navbar navbar-sticky navbar-expand-lg navbar-dark">
            <div class="container position-relative">
                <a class="navbar-brand" href="/">
                    <img class="navbar-brand-regular" src="/assets/img/bricksee-logo.png" alt="brand-logo">
                    <img class="navbar-brand-sticky" src="/assets/img/bricksee-logo.png" alt="sticky brand-logo">
                </a>
                <button class="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="navbar-inner">
                    <!--  Mobile Menu Toggler -->
                    <button class="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <nav>
                        <ul class="navbar-nav" id="navbar-nav">
                            <li class="nav-item">
                                <router-link class="nav-link scroll" to="/#" href="/#">
                                    Home
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link scroll" to="/#features" href="/#features">Features</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link scroll" to="/#contact" href="/#contact">Contact</router-link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
</template>

<script>
export default {
    
}
</script>

<style>

</style>