<template>
    <footer class="footer-area">
            <!-- Footer Top -->
            <div class="footer-top ptb_100">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-lg-3">
                            <!-- Footer Items -->
                            <div class="footer-items">
                                <!-- Logo -->
                                <a class="navbar-brand" >
                                    <img class="logo" src="/assets/img/app-icon.png" alt="">
                                </a>
                                <p class="mt-2 mb-3">Reorganising your bricks, the easy way</p>
                                <!-- Social Icons -->
                                <div class="social-icons d-flex">
                                    <a class="facebook" :href="config.socials.facebook" target="_blank">
                                        <i class="fab fa-facebook-f"></i>
                                        <i class="fab fa-facebook-f"></i>                                    </a>
                                    <a class="twitter" :href="config.socials.twitter" target="_blank">
                                        <i class="fab fa-twitter"></i>
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                    <a class="instagram" :href="config.socials.instagram" target="_blank">
                                        <i class="fab fa-instagram"></i>
                                        <i class="fab fa-instagram"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-12 col-sm-6 col-lg-3">

                            <div class="footer-items">

                                <h3 class="footer-title mb-2">Useful Links</h3>
                                <ul>
                                    <li class="py-2"><a href="#">Home</a></li>
                                    <li class="py-2"><a href="#">About Us</a></li>
                                    <li class="py-2"><a href="#">Services</a></li>
                                    <li class="py-2"><a href="#">Blog</a></li>
                                    <li class="py-2"><a href="#">Contact</a></li>
                                </ul>
                            </div>

                        </div> -->
                        <div class="col-12 col-sm-6 col-lg-6">
                            <!-- Footer Items -->
                            <div class="footer-items">
                                <!-- Footer Title -->
                                <h3 class="footer-title mb-2">Product Help</h3>
                                <ul>
                                    <li class="py-2"><router-link to="/coming-soon">FAQ</router-link></li>
                                    <li class="py-2"><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                                    <li class="py-2"><router-link to="/terms-conditions">Terms &amp; Conditions</router-link></li>
                                    <li class="py-2"><router-link to="/#contact">Contact</router-link></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3">
                            <!-- Footer Items -->
                            <div class="footer-items">
                                <!-- Footer Title -->
                                <h3 class="footer-title mb-2">Download</h3>
                                <!-- Store Buttons -->
                                <div class="button-group store-buttons store-black d-flex flex-wrap">
                                    <AppStoreDownload type="black"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Footer Bottom -->
            <div class="footer-bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <!-- Copyright Area -->
                            <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                <!-- Copyright Left -->
                                <div class="copyright-left">&copy; Copyright {{ getYear() }} - Bricksee All rights reserved.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
</template>

<script>
import config from '../../config.js'
import { event } from 'vue-gtag'
import AppStoreDownload from "@/components/Shared/AppStoreDownload";
import Config from "@/config"

export default {
  components: {AppStoreDownload},
  data() {
    return {
      config: Config
    }
  },
  methods: {
        getYear() {
            return new Date().getFullYear()
        }
    },
}
</script>

<style>

</style>