import { createRouter, createWebHistory } from 'vue-router'
import Index from '@/themes/index'
import Download from '@/components/InnerPages/DownloadPage/download'
import ThankYou from '@/components/InnerPages/ThankYou/thankyou'
import ComingSoon from '@/components/InnerPages/ComingSoon/coming-soon'
import Error from '@/components/InnerPages/Error/404'
import Reviews from '@/components/InnerPages/ReviewPage/reviews'
import Faq from '@/components/InnerPages/FaqPage/faq'
import Contact from '@/components/InnerPages/ContactPage/contact'
import PrivacyPolicy from "@/components/Legal/Privacy/privacyPolicy";
import TermsConditions from "@/components/Legal/Terms/termsConditions";
import CountDown from "@/components/Random/Countdown";
import Home from "@/themes/home"

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/download',
      name: 'Download',
      component: Download
    },
    {
      path: '/thankyou',
      name: 'ThankYou',
      component: ThankYou
    },
    {
      path: '/coming-soon',
      name: 'ComingSoon',
      component: ComingSoon
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'Error',
      component: Error
    },
    {
      path: '/reviews',
      name: 'Reviews',
      component: Reviews
    },
    {
      path: '/faq',
      name: 'Faq',
      component: Faq
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact
    },
    {
      path: '/privacy-policy',
      name: 'Privacy Policy',
      component: PrivacyPolicy
    },
    {
      path: '/terms-conditions',
      name: 'Terms and Conditions',
      component: TermsConditions
    },
    {
      path: '/nina/countdown',
      name: 'Countdown',
      component: CountDown
    }
  ],
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

