<template>
    <div>
        <ScrollupSection />
        <div class="main">
            <HeaderSection />
            <FooterSection />
        </div>
    </div>
</template>

<script>
import ScrollupSection from '../../ScrollupSection/scrollUp'
import HeaderSection from '../../HeaderSection/headerOne'
import FooterSection from '../../FooterSection/footerTwo'

export default {
    name: 'ReviewsItem',
    components: {
        ScrollupSection,
        HeaderSection,
        FooterSection
    }
}
</script>

<style>

</style>