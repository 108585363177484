<template>
    <a @click="appstoreDownload()" href="#">
        <img src="/assets/img/App%20Store%20Download/black_appstore.svg" v-if="type === 'black'" alt="Download on the App Store">
        <img src="/assets/img/App%20Store%20Download/white_appstore.svg" v-if="type === 'white'" alt="Download on the App Store">
    </a>
</template>

<script>
import config from '../../config.js'
import { event } from 'vue-gtag'

export default {
    props: {
      type: String
    },
    methods: {
        appstoreDownload() {
            event('appstore_download')
            window.open(config.appstoreDownloadURL, '_blank')
        }
    },
}
</script>