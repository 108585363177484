<template>
    <div>
        <ScrollupSection />
        <div class="main">
            <HeaderSection />
            <InnerBreadcrumb />
            <DownloadSection />
            <ContactSection />
            <FooterSection />
        </div>
    </div>
</template>

<script>
import ScrollupSection from '../../ScrollupSection/scrollUp'
import HeaderSection from '../../HeaderSection/headerOne'
import InnerBreadcrumb from '../InnerBreadcrumb/breadcrumbThree'
import DownloadSection from '../../DownloadSection/download'
import ContactSection from '../../ContactSection/contactOne'
import FooterSection from '../../FooterSection/footerTwo'

export default {
    name: 'FaqItem',
    components: {
        ScrollupSection,
        HeaderSection,
        InnerBreadcrumb,
        DownloadSection,
        ContactSection,
        FooterSection
    }
}
</script>

<style>

</style>