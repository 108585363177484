<template>
    <section class="section work-area overflow-hidden ptb_100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-6">
                    <!-- Work Content -->
                    <div class="work-content text-center">
                        <h2 class="">How we work?</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-4">
                    <!-- Single Work -->
                    <div class="single-work text-center p-3">
                        <!-- Work Icon -->
                        <div class="work-icon">
                            <v-icon name="la-cloud-download-alt-solid" scale="4" />
                        </div>
                        <h3 class=" py-3">Install the App</h3>
                        <p class="">Download the app from the app store</p>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <!-- Single Work -->
                    <div class="single-work text-center p-3">
                        <!-- Work Icon -->
                        <div class="work-icon">
                            <v-icon name="co-library-add" scale="4" />
                        </div>
                        <h3 class=" py-3">Add all your sets</h3>
                        <p class="">Search from over 10,000 sets, and add all the sets you own. The more the
                            merrier</p>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <!-- Single Work -->
                    <div class="single-work text-center p-3">
                        <!-- Work Icon -->
                        <div class="work-icon">
                            <v-icon name="co-magnifying-glass" scale="4" />
                        </div>
                        <h3 class=" py-3">Recover hidden bricks</h3>
                        <p class="">Lay your bricks out evenly on the ground, take a photo and bring them back to
                            life</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "WorkSection"
}
</script>

<style></style>