<template>
    <section id="contact" class="contact-area bg-gray ptb_100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-6">
                    <!-- Section Heading -->
                    <div class="section-heading text-center">
                        <h2 class="text-capitalize">Contact Us</h2>
                    </div>
                </div>
            </div>
            <div class="row justify-content-between">

                <div class="col-12">
                    <!-- Contact Box -->
                    <div class="contact-box text-center">
                        <!-- Contact Form -->
                        <div v-if="response != null" class="alert alert-success mt-3" role="alert">
                            {{ response }}
                        </div>
                        <form id="contact-form" method="POST" @submit.prevent="submit">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <input type="email" class="form-control" name="email" placeholder="Email"
                                            v-model="email">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <textarea class="form-control" name="message" placeholder="Message" required
                                            v-model="message"></textarea>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button type="submit" class="btn btn-lg btn-block mt-3">
                                        <span class="text-white pr-3"><i class="fas fa-paper-plane"></i></span>Send
                                        Message
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            email: '',
            message: '',
            response: null
        }
    },
    methods: {
        submit() {
            fetch('https://bff.bricksee.app/contact/submit', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: this.email,
                    message: this.message
                })
            })
            .then((response) => {
                this.response = response.message;
                this.email = '';
                this.message = '';
            })
        }
    }
}
</script>

<style>

</style>