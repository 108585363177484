<template>
    <section class="section discover-area bg-gray overflow-hidden ptb_100">
            <div class="container">
                <div class="row justify-content-between align-items-center">
                    <div class="col-12 col-lg-4 order-2 order-lg-1">
                        <!-- Discover Thumb -->
                        <div class="service-thumb discover-thumb mx-auto text-center">
                            <img src="/assets/img/App%20Store/part-preview.webp" alt="">
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 order-1 order-lg-2">
                        <!-- Discover Text -->
                        <div class="discover-text px-0 px-lg-4 pt-4 pt-lg-0">
                            <h2 class="pb-4">In-depth part information </h2>
                            <!-- Check List -->
                            <ul class="check-list">
                                <li v-for="feature in features" :key=feature.body class="py-1">
                                    <div class="list-box media">
                                      <span class="icon"><i class="fas fa-check"></i></span>
                                      <span class="media-body pl-2">{{ feature.body }}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
export default {
  data() {
    return {
      features: [
        {
          body: "See how many sets contain your detected part and which sets you own"
        },
        {

          body: "Add a part directly to a set you haven’t saved yet"
        },
        {

          body: "Access more information about your searched part "
        },
        {

          body: "Recall the last time you searched this part for matching sets"
        }
      ]
    }
  }
}
</script>

<style>

</style>