<template>
  <div class="homepage-2">
    <ScrollupSection />
    <div class="main">
      <HeaderSection />
      <HeroSection />
      <FeatureSection />
      <WorkSection />
      <PartInformationDetailView />
      <ServiceSection />
      <ContactSection />
      <FooterSection />
    </div>
  </div>
</template>

<script>
import ScrollupSection from '../components/ScrollupSection/scrollUp'
import HeaderSection from '../components/HeaderSection/headerOne'
import HeroSection from '../components/HeroSection/heroOne'
import PartInformationDetailView from '../components/DiscoverSection/partInformationDetailView'
import ServiceSection from '../components/ServiceSection/serviceTwo'
import WorkSection from '../components/WorkSection/work'
import ContactSection from '../components/ContactSection/contactOne'
import FooterSection from '../components/FooterSection/footerTwo'

export default {
  name: 'ThemeTwo',
  components: {
    ScrollupSection,
    HeaderSection,
    HeroSection,
    WorkSection,
    PartInformationDetailView,
    ServiceSection,
    ContactSection,
    FooterSection
  }
}
</script>

<style></style>