<template>
  <section class="section service-area bg-inherit overflow-hidden ptb_100">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-12 col-lg-6 order-2 order-lg-1">
          <!-- Service Text -->
          <div class="service-text pt-4 pt-lg-0">
            <h2 class="mb-4">And detailed set information</h2>
            <!-- Service List -->
            <ul class="service-list">
              <li v-for="feature in features" :key="feature.body" class="single-service media py-2">
                <div class="list-box media">
                  <span class="icon"><i class="fas fa-check"></i></span>
                  <span class="media-body pl-2">{{ feature.body }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-lg-4 order-1 order-lg-2">
          <!-- Service Thumb -->
          <div class="service-thumb discover-thumb mx-auto">
            <img src="/assets/img/App%20Store/set-information.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      features: [
        {
          icon: "fa-cart-arrow-down",
          body: "See all the parts that are contained within your set"
        },
        {
          icon: "fa-cart-arrow-down",
          body: "Easily see how you're tracking towards rebuilding your set"
        },
        {
          icon: "fa-cart-arrow-down",
          body: "Access more information about your set"
        },
        {
          icon: "fa-cart-arrow-down",
          body: "View instructions in-app and track your progress"
        }
      ]
    }
  }
}
</script>