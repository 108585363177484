<template>
  <div class="wrapper">
    <div class="countdown-main-container">
      <h1 id="headline">NYC Bae + Melb Lank meetup</h1>
      <h4>in</h4>
      <div id="countdown">
        <div class="countdown-container">
          <div><div id="days" class="digits"></div><div class="type">days</div></div>
          <div><div id="hours" class="digits"></div><div class="type">Hours</div></div>
          <div><div id="minutes" class="digits"></div><div class="type">Minutes</div></div>
          <div><div id="seconds" class="digits"></div><div class="type">Seconds</div></div>
        </div>
      </div>
      <img src="https://www.freepnglogos.com/uploads/rose-png/roses-hd-png-transparent-rose-hd-image-14.png" alt="">
      <div id="dvd"></div>
    </div>
  </div>
</template>


<style>
.wrapper {
  height: 100vh;
  margin: 0;
  background: linear-gradient(0deg, rgba(65, 105, 225, 0.5), rgba(65, 105, 225, 0.5)), url("https://i.natgeofe.com/n/874df281-d3e0-489a-98c0-6b840023b828/newyork_NationalGeographic_2328428_2x1.jpg");
}

.countdown-main-container {
  color: #333;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
  background: rgba(0, 180, 137, 0.8);
  border-radius: 10px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.countdown-main-container h1 {
  font-weight: normal;
  letter-spacing: .125rem;
  text-transform: uppercase;
}

.countdown-main-container img {
  height: 150px;
}

.countdown-container {
  display: flex;
  justify-content: center; /* aligns items horizontally in the center */
  align-items: center;
  padding-top: 40px;
}

@media only screen and (max-width: 600px) {
  .countdown-container {
    display: inline-grid;
  }
}
.countdown-container .type {
  font-size: 1.5em;
  list-style-type: none;
  padding: 1em;
  text-transform: uppercase;
}

.countdown-container .digits {
  display: block;
  font-size: 4.5rem;
}

#dvd {
  position: absolute;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url("https://www.pngkey.com/png/full/76-766756_koala-bear-cartoon-koala-with-transparent-background.png");
  color: transparent;
}

#dvd:before {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e900";
  font-size: 175px;
}

</style>

<script>
export default {
  name: 'CountDown',
  mounted() {
    const targetDate = new Date('March 8, 2023 07:00:00 EST').getTime();

    const countdown = setInterval(() => {
          const now = new Date().getTime();

          // Calculate the remaining time
          const distance = targetDate - now;
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);

          document.getElementById("days").innerText = Math.floor(days),
          document.getElementById("hours").innerText = Math.floor(hours),
          document.getElementById("minutes").innerText = Math.floor(minutes),
          document.getElementById("seconds").innerText = Math.floor(seconds);
          //seconds
        }, 0)

      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', '/assets/js/countdown.js')
      document.head.appendChild(recaptchaScript)
  }
}
</script>