<template>
  <div>
    <ScrollupSection />
    <div class="main">
      <HeaderSection />
      <InnerBreadcrumb title='Terms and Conditions' />
      <section id="contact" class="contact-area bg-gray ptb_100">
        <div class="container">
          <TermsConditionsContent />
        </div>
      </section>
      <FooterSection />
    </div>
  </div>
</template>

<script>
import ScrollupSection from '../../ScrollupSection/scrollUp'
import HeaderSection from '../../HeaderSection/headerOne'
import FooterSection from '../../FooterSection/footerTwo'
import InnerBreadcrumb from '../../InnerPages/InnerBreadcrumb/breadcrumbThree'
import TermsConditionsContent from './contents'

export default {
  name: 'PrivacyPolicy',
  components: {
    ScrollupSection,
    InnerBreadcrumb,
    TermsConditionsContent,
    HeaderSection,
    FooterSection
  }
}
</script>

<style>

</style>