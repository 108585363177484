<template>
    <div class="homepage-3 inner">
        <ScrollupSection />
        <div class="main">
            <HeaderSection />
            <section id="home" class="section bg-overlay h-100vh overflow-hidden">
                <div class="container h-100">
                    <div class="row align-items-center justify-content-center h-100">
                        <div class="col-12 col-md-7">
                            <div class="welcome-intro text-center">
                                <h1 class="text-white">We are coming soon!</h1>
                                <p class="text-white my-4">Our website is under construction. We'll be here soon with our new awesome site.</p>
                                <div class="countdown-times mb-4">
                                    <div class='countdown d-flex justify-content-center' data-date="2021-12-09"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import ScrollupSection from '../../ScrollupSection/scrollUp'
import HeaderSection from '../../HeaderSection/headerOne'

export default {
    name: 'ComingSoon',
    components: {
        ScrollupSection,
        HeaderSection
    }
}
</script>

<style>

</style>